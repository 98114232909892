import styled from "@emotion/styled";

export default function Store() {
  const handleStore = (type: string) => {
    if (type === "apple") {
      window.open(
        "https://apps.apple.com/kr/app/%EC%A1%B0%EC%9D%B4%EC%BD%94%EA%B7%B8-joycog/id6473777579"
      );
    } else {
      window.open(
        "https://play.google.com/store/apps/details?id=com.eMotiv.rudyadventure&hl=ko-KR"
      );
    }
  };

  return (
    <Wrapper>
      <DownLoadImg />
      <h1>지금 JoyCog 모바일 앱을 다운로드하세요!</h1>
      <ButtonContainer>
        <button onClick={() => handleStore("apple")}>
          <Img
            url="/images/coupon/apple.png"
            width="24px"
            height="24px"
            mgRight="8px"
          />
          App Store
        </button>
        <button onClick={() => handleStore("google")}>
          <Img
            url="/images/coupon/google.png"
            width="19px"
            height="19px"
            mgRight="11px"
          />
          Google Play
        </button>
      </ButtonContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    color: #fff;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
  }

  @media (max-width: 679px) {
    & > h1 {
      width: 253px;
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const DownLoadImg = styled.div`
  background: url(/images/coupon/img_download.png) no-repeat;
  background-size: cover;
  width: 350px;
  height: 132px;
  margin-bottom: 9px;

  @media (max-width: 679px) {
    width: 278px;
    height: 105px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  margin-bottom: 163px;
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 70px;
    border-radius: 35px;
    border: 1px solid #fff;
    background-color: transparent;

    color: #fff;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :nth-of-type(1) {
      margin-right: 32px;
    }
  }

  @media (max-width: 979px) {
    margin-bottom: 120px;
  }

  @media (max-width: 679px) {
    flex-direction: column;
    align-items: center;

    & > button {
      width: 180px;
      height: 48px;
      font-size: 18px;
      :nth-of-type(1) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
`;

const Img = styled.div<{
  url: string;
  width: string;
  height: string;
  mgRight: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: url(${(props) => props.url}) no-repeat;
  background-size: cover;
  margin-right: ${(props) => props.mgRight};
`;
