import styled from "@emotion/styled";

export default function Page10() {
  return (
    <Wrapper>
      <div>
        조이코그
        <br />
        인지능력 강화 프로그램
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 560px;
  background: url("/images/report/page11.webp") no-repeat center;
  background-size: cover;
  color: #fff;
  text-align: center;
  font-family: "Cafe24 Ssurround OTF";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 979px) {
    height: 607px;

    color: #fff;
    font-size: 38.348px;

    & > div {
      width: 200px;
    }
  }
`;
