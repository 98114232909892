import styled from "@emotion/styled";

export default function Page5() {
  return (
    <Wrapper>
      <h1>
        추정 인지 <span>수준 및 설명</span>
      </h1>
      <div>주의력 / 억제력 / 작업기억력 / 처리속도</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 560px;
  background: url("/images/report/page6.webp") no-repeat center;
  background-size: cover;
  color: #fff;
  text-align: center;
  font-family: Cafe24 Ssurround OTF;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  & > h1 {
    color: #fff;
    font-family: "Cafe24 Ssurround OTF";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }

  & > div {
    color: #fff;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 979px) {
    height: 607px;

    & > h1 {
      font-size: 38px;

      & > span {
        display: block;
      }
    }

    & > div {
      font-size: 18px;
    }
  }
`;
