import styled from "@emotion/styled";
import { ReactComponent as LogoSVG } from "../../assets/page1_1.svg";

import dateFilter from "../../hooks/useDate";
import { ReportData } from "../../types/report";

interface Props {
  data?: ReportData;
}

export default function Page1({ data }: Props) {
  const date = data?.screenDate;
  const filteredDate = dateFilter(date);
  return (
    <Wrapper>
      <div>
        <Logo />
      </div>
      <h1>인지능력 평가 결과 리포트</h1>
      <div>
        <h2>{filteredDate}</h2>
        <h2>측정 아동 나이 | 만 {data?.age}세</h2>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 560px;
  background: url("/images/report/page1.png") no-repeat center;
  background-size: cover;
  color: #fff;
  text-align: center;
  font-family: Cafe24 Ssurround OTF;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  & > div {
    :nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 274px;
      height: 92px;
      background-color: #2c55fb;
      border-radius: 49.5px;

      margin-top: 191px;
    }

    :nth-of-type(2) {
      display: flex;

      & > h2 {
        color: #fff;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        :nth-of-type(1) {
          margin-right: 500px;
        }
      }
    }
  }

  & > h1 {
    margin-top: 24px;
    margin-bottom: 138px;
    color: #fff;
    text-align: center;
    font-family: "Cafe24 Ssurround OTF";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (max-width: 979px) {
    height: 607px;
    background: url("/images/report/mobile_page1.png") no-repeat center;
    background-size: cover;
    & > div {
      :nth-of-type(1) {
        width: 168px;
        height: 56px;
        margin-top: 216px;
      }

      :nth-of-type(2) {
        display: block;
        & > h2 {
          :nth-of-type(1) {
            margin-right: 0;
          }
        }
      }

      & > h2 {
        :nth-of-type(1) {
          margin-bottom: 11px;
        }
      }
    }

    & > h1 {
      margin-top: 19px;
      margin-bottom: 122px;
      width: 218px;
      height: 100px;

      font-size: 38px;
    }
  }
`;

const Logo = styled(LogoSVG)`
  width: 210px;
  height: 60px;

  @media (max-width: 979px) {
    width: 130px;
    height: 37px;
  }
`;
