import styled from "@emotion/styled";

import dateFilter from "../../hooks/useDate";
import { ReportData } from "../../types/report";

interface Props {
  data?: ReportData;
}

const HIGH = "주의집중력·충동조절능력이 높은 것";
const HIGH_1 = "으로 판단됩니다.";
const HIGH_2 =
  " 인지강화 프로그램을 꾸준히 이용하여 인지능력을 향상시켜주세요.";
const DANGER = "주의집중력·충동조절능력 저하가 우려";
const DANGER_1 = "됩니다.";
const DANGER_2 =
  " 학업 및 일상생활에 어려움을 겪는다면 의료기관 방문을 고려해 보세요.";
const DOUBT = "주의집중력·충동조절능력이 낮은 것으로 판단";
const DOUBT_1 = "됩니다.";
const DOUBT_2 =
  " 가급적 빠른 시일 내에 의료기관에 방문하여 전문가의 상담을 받아보세요.";

export default function Page2({ data }: Props) {
  const date = data?.screenDate;
  const filteredDate = dateFilter(date);
  return (
    <>
      {data && (
        <Wrapper>
          <Title>
            <div />
            <h1>JoyCog 인지능력 평가란?</h1>
          </Title>
          <SubTitle>
            <p>
              본 프로그램은 만 6~12세의 학령기 아동의 주의력을 평가하기 위한
              주의력 약식 검사로, 주의력과 관련 있는 여러 가지 인지 요인들을
              측정하고 평가합니다.
            </p>
          </SubTitle>
          <TextContainer>
            연속수행검사(Continuous Performance Test) 형식을 활용하여 어린이의
            주의력, 충동성, 처리속도를 측정하고, 숫자 외우기 과제(Digit Span)를
            통해 어린이의 작업기억력을 측정합니다.
            <br />
            연속수행검사는 특정 시각 자극에 반응하는 능력을 측정하는 시각 주의력
            과제, 특정 청각 자극에 반응하는 능력을 측정하는 청각 주의력 과제로
            구성되며, 숫자 외우기 검사는 시각적으로 제시되는 숫자를 기억하여
            순서대로/거꾸로 응답하는 작업기억력 과제로 구성됩니다.
          </TextContainer>

          <ImgContainer
            url={
              data?.probability <= 45.9
                ? "/images/report/page2_1.png"
                : data?.probability > 75.9
                ? "/images/report/page2_3.png"
                : "/images/report/page2_2.png"
            }
            color={
              data?.probability <= 45.9
                ? "#3391E8"
                : data?.probability > 75.9
                ? "#F88DAD"
                : "#FF8A00"
            }
          >
            <h1>
              우리아이
              <br />
              주의력 예보는?
            </h1>
            <h2>
              {filteredDate}
              <br />만 {data?.age}세
            </h2>
            <h3>
              {data?.probability <= 45.9
                ? "양호해요"
                : data?.probability > 75.9
                ? "의심스러워요"
                : "주의가 필요해요"}
            </h3>
            {data?.probability <= 45.9 ? (
              <span>
                설문과 과제 수행 데이터를 분석한 결과 <span>{HIGH}</span>
                {HIGH_1}
                <p>{HIGH_2}</p>
              </span>
            ) : data?.probability > 75.9 ? (
              <span>
                설문과 과제 수행 데이터를 분석한 결과 <span>{DOUBT}</span>
                {DOUBT_1}
                <p>{DOUBT_2}</p>
              </span>
            ) : (
              <span>
                설문과 과제 수행 데이터를 분석한 결과 <span>{DANGER}</span>
                {DANGER_1}
                <p>{DANGER_2}</p>
              </span>
            )}
          </ImgContainer>
          <Footer>
            <FooterImg />
            <div>
              본 평가는 아동의 건강한 성장에 도움을 주기 위한 것입니다. 의학적
              진단이 아니므로 질병의 유무를 판단할 수 없습니다.
            </div>
          </Footer>
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  width: 792px;
  @media (max-width: 979px) {
    width: 318px;
  }
`;

const Title = styled.div`
  display: flex;
  margin-top: 127px;
  margin-bottom: 16px;

  & > div {
    width: 4px;
    height: 23px;
    border-radius: 60px;
    background: #ffe9a3;
    margin-right: 8px;
  }

  & > h1 {
    color: #000;
    font-family: "Cafe24 Ssurround OTF";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (max-width: 979px) {
    margin-top: 80px;
  }
`;

const SubTitle = styled.div`
  margin-bottom: 24px;
  & > p {
    color: #000;
    text-align: justify;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 28.8px */
  }

  @media (max-width: 979px) {
    margin-top: 16px;
  }
`;

const TextContainer = styled.div`
  border-radius: 6px;
  background: #f6f6f6;
  padding: 16px;
  margin-bottom: 24px;

  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
`;

const ImgContainer = styled.div<{ url: string }>`
  height: 397px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 0 24px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: cover;

  & > h1 {
    margin-top: 40px;
    margin-bottom: 31px;
    color: #000;
    font-family: Cafe24 Ssurround OTF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & > h2 {
    margin-bottom: 69px;
    color: #000;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & > h3 {
    margin-bottom: 8px;
    color: ${(props) => props.color};
    font-family: Cafe24 Ssurround OTF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & > span {
    color: #000;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 24px */

    & > span {
      font-weight: 700;
    }
  }

  @media (max-width: 979px) {
    background: url(${(props) => props.url}) no-repeat top right;
    height: 559px;

    & > h1 {
      margin-top: 200px;
      margin-bottom: 24px;
    }

    & > h2 {
      margin-bottom: 40px;
    }

    & > span {
      & > p {
        display: inline;
      }
    }
  }
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 103px;
  margin-bottom: 177px;
  height: 102px;
  background-color: #f6f6f6;
  border-radius: 10px;

  & > div {
    width: 415px;
    color: #000;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  @media (max-width: 979px) {
    margin-top: 102px;
    margin-bottom: 104px;
    height: 129px;

    & > div {
      width: 286px;
    }
  }
`;

const FooterImg = styled.span`
  position: absolute;
  bottom: 85px;
  width: 146px;
  height: 86px;
  background: url("/images/report/page2_Footer.png") no-repeat center;
  background-size: contain;

  @media (max-width: 979px) {
    bottom: 113px;
  }
`;
