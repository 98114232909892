import styled from "@emotion/styled";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ReactComponent as DeleteSvg } from "../../assets/coupon/btn_delete.svg";
import Registration from "./Registration";
import Modal from "../portal/Modal";
import { PostCouponData } from "../../api/coupon";

interface Props {
  uid: string | null;
  setUid: Dispatch<SetStateAction<string | null>>;
}

export default function Coupon({ uid, setUid }: Props) {
  const [coupon, setCoupon] = useState("");
  const [isModal, setIsModal] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleCouponChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(event.target.value);
  };

  const handleLogout = () => {
    document.cookie =
      "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    setUid("");
    setIsSuccess(true);
  };

  const handlePostCoupon = () => {
    if (coupon === "") {
      setIsModal("유효한 쿠폰 코드 아님");
      return;
    }

    PostCouponData({ purchaseCode: coupon, uid: uid as string })
      .then((data) => {
        if (data.data.status === 200) {
          handleLogout();
          return;
        }

        if (data.data.status === 20000) {
          setIsModal("유효한 쿠폰 코드 아님");
          return;
        }

        if (data.data.status === 20001 || data.data.status === 20020) {
          setIsModal("이미 사용된 쿠폰");
          return;
        }

        if (data.data.status === 20002) {
          setIsModal("유효기간 만료 쿠폰");
          return;
        }

        if (data.data.status === 20003) {
          setIsModal("이미 쿠폰을 보유");
          return;
        }

        if (data.data.status === 20005 || data.data.status === 20029) {
          setIsModal("등록 불가 쿠폰");
          return;
        }

        if (data.data.status === 20022) {
          setIsModal("사용 중지된 쿠폰");
          return;
        }

        if (data.data.status === 20021) {
          setIsModal("환불 처리된 상품");
          return;
        }
      })

      .catch((error) => {
        console.error("API 요청 실패:", error);
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <>
      {!isSuccess ? (
        <>
          <RudyImg />
          <Wrapper
            border={coupon.length < 1 ? "#ebebeb" : "#a9a9a9"}
            bgColor={coupon.length < 1 ? "#f4f4f4" : "#fff"}
            focusBorder={coupon.length < 1 ? "#2c55fb" : "#BDBDBD"}
            color={coupon.length < 1 ? "#2c55fb" : "#000"}
          >
            <Img />
            <h1>쿠폰 입력</h1>
            <h2>{uid}</h2>
            <div>
              <input
                type="text"
                placeholder="쿠폰 코드를 입력해주세요"
                value={coupon}
                onChange={handleCouponChange}
                maxLength={8}
                ref={inputRef}
              />
              {coupon.length >= 1 && (
                <DeleteButton onClick={() => setCoupon("")} />
              )}
            </div>
            <button onClick={handlePostCoupon}>쿠폰 사용 하기</button>
            <button onClick={handleLogout}>로그아웃</button>
            <TextContainer>
              <h1>주의 사항</h1>
              <ul>
                <li>
                  회원번호는 게임 내 설정메뉴에서
                  <br />
                  확인할 수 있습니다.
                </li>
                <li>이미 사용된 쿠폰은 등록할 수 없습니다.</li>
              </ul>
            </TextContainer>
          </Wrapper>
        </>
      ) : (
        <Registration />
      )}
      {isModal === "유효한 쿠폰 코드 아님" && (
        <Modal
          title="존재하지 않는 쿠폰입니다."
          subTitle="쿠폰 코드를 확인해주세요."
          onClose={() => setIsModal("")}
        />
      )}
      {isModal === "유효기간 만료 쿠폰" && (
        <Modal
          title="등록 유효기간이 만료된 쿠폰입니다."
          onClose={() => setIsModal("")}
        />
      )}
      {isModal === "이미 쿠폰을 보유" && (
        <Modal
          title="쿠폰을 보유하고 있습니다."
          subTitle="쿠폰 등록이 취소됩니다."
          onClose={() => setIsModal("")}
        />
      )}
      {isModal === "이미 사용된 쿠폰" && (
        <Modal title="이미 사용된 쿠폰입니다." onClose={() => setIsModal("")} />
      )}
      {isModal === "등록 불가 쿠폰" && (
        <Modal
          title="아직 등록이 불가능한 쿠폰입니다."
          subTitle="등록 가능한 일자를 확인해주세요."
          onClose={() => setIsModal("")}
        />
      )}
      {isModal === "사용 중지된 쿠폰" && (
        <Modal
          title="사용이 중지된 쿠폰입니다."
          onClose={() => setIsModal("")}
        />
      )}
      {isModal === "환불 처리된 상품" && (
        <Modal title="환불 처리된 쿠폰입니다." onClose={() => setIsModal("")} />
      )}
    </>
  );
}

const Wrapper = styled.div<{
  focusBorder: string;
  bgColor: string;
  border: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 384px;
  height: 581px;
  margin-top: 220px;
  margin-bottom: 120px;
  border-radius: 16px;
  background: #fff;

  & > h1 {
    margin-top: 32px;
    margin-bottom: 16px;

    color: #000;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & > h2 {
    width: 256px;
    height: 16px;
    padding: 16px 14px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-bottom: 8px;

    color: #a9a9a9;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & > div {
    position: relative;

    & > input {
      width: 256px;
      height: 16px;
      padding: 16px 14px;
      border-radius: 8px;
      border: 1px solid ${(props) => props.border};
      margin-bottom: 24px;

      outline: none;
      background: ${(props) => props.bgColor};
      color: ${(props) => props.color};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      :focus {
        border: 1px solid ${(props) => props.focusBorder};
        background-color: #fff;
      }

      ::placeholder {
        color: #000;
      }
    }
  }

  & > button {
    :nth-of-type(1) {
      width: 286px;
      height: 50px;
      border-radius: 8px;
      background-color: #000;
      border: none;
      margin-bottom: 16px;

      color: #fff;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    :nth-of-type(2) {
      margin-bottom: 32px;
      color: #000;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border: none;
      background-color: #fff;
    }
  }

  @media (max-width: 679px) {
    width: 318px;

    & > h1 {
      margin-bottom: 24px;
    }

    & > button {
      :nth-of-type(2) {
        margin-bottom: 16px;
      }
    }
  }
`;

const Img = styled.div`
  width: 135px;
  height: 36px;

  margin-top: 76px;

  background: url(/images/coupon/logo.png) no-repeat;
  background-size: cover;
  @media (max-width: 679px) {
    margin-top: 54px;
  }
`;

const TextContainer = styled.div`
  width: 286px;
  & > h1 {
    color: #99999c;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }

  & > ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
      color: #99999c;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 8px;
    }
  }
`;

const DeleteButton = styled(DeleteSvg)`
  position: absolute;
  top: 13px;
  right: 13px;

  cursor: pointer;
`;

const RudyImg = styled.div`
  background: url(/images/coupon/img_rudy.png) no-repeat;
  background-size: cover;
  width: 312px;
  height: 242px;
  top: 72px;
  position: absolute;

  @media (max-width: 679px) {
    width: 250px;
    height: 194px;
    top: 88px;
  }
`;
