import { Global, css } from "@emotion/react";
import emotionReset from "emotion-reset";

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        ${emotionReset}

        body {
          font-family: "Noto Sans KR", "Cafe24 Ssurround OTF", "Noto Sans",
            sans-serif;
        }
        @font-face {
          font-family: "Cafe24 Ssurround OTF";
          src: url("/fonts/Cafe24Ssurround-v2.0.ttf"), format("ttf");
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: "Pretendard";
          font-weight: 800;

          src: local("Pretendard ExtraBold"),
            url("/fonts/Pretendard-ExtraBold.woff2") format("woff2"),
            url("/fonts/Pretendard-ExtraBold.woff") format("woff");
        }

        @font-face {
          font-family: "Pretendard";
          font-weight: 700;

          src: local("Pretendard Bold"),
            url("/fonts/Pretendard-Bold.woff2") format("woff2"),
            url("/fonts/Pretendard-Bold.woff") format("woff");
        }

        @font-face {
          font-family: "Pretendard";
          font-weight: 600;

          src: local("Pretendard SemiBold"),
            url("/fonts/Pretendard-SemiBold.woff2") format("woff2"),
            url("/fonts/Pretendard-SemiBold.woff") format("woff");
        }

        @font-face {
          font-family: "Pretendard";
          font-weight: 500;

          src: local("Pretendard Medium"),
            url("/fonts/Pretendard-Medium.woff2") format("woff2"),
            url("/fonts/Pretendard-Medium.woff") format("woff");
        }

        @font-face {
          font-family: "Pretendard";
          font-weight: 400;

          src: local("Pretendard Regular"),
            url("/fonts/Pretendard-Regular.woff2") format("woff2"),
            url("/fonts/Pretendard-Regular.woff") format("woff");
        }

        button {
          cursor: pointer;
        }
      `}
    />
  );
};

export default GlobalStyle;
