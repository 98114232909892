import base64 from "base-64";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function SuccessCoupon() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("accessToken");
    if (!accessToken) {
      return;
    }

    const payload = accessToken.substring(
      accessToken.indexOf(".") + 1,
      accessToken.lastIndexOf(".")
    );
    const dec = JSON.parse(base64.decode(payload));

    const uid = dec.uid;

    const expires = new Date();
    expires.setTime(expires.getTime() + 60 * 60 * 2000);

    document.cookie = `accessToken=${accessToken}; expires=${expires.toUTCString()}; path=/`;
    document.cookie = `uid=${uid}; expires=${expires.toUTCString()}; path=/`;

    navigate("/coupon");
  }, [location.search, navigate]);
  return <></>;
}
