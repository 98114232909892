import styled from "@emotion/styled";
import React from "react";

import { ReactComponent as SVG } from "../../assets/page3.svg";
import { ReactComponent as SVG_1 } from "../../assets/page3_1.svg";
import { ReactComponent as SVG_2 } from "../../assets/page3_2.svg";
import { ReactComponent as SVG_3 } from "../../assets/page3_3.svg";
import { ReactComponent as SVG_4 } from "../../assets/page3_4.svg";
import { ReportData } from "../../types/report";

interface Props {
  data?: ReportData;
}

interface TaskData {
  title: string;
  [key: string]: string;
}

interface DataType {
  [key: string]: TaskData[];
}

export default function Page3({ data }: Props) {
  const bestScores = data?.bestScore || [];
  const worstScores = data?.worstScore || [];

  const taskType1 = bestScores.some((item) => item?.includes("task1"))
    ? "ADSV"
    : bestScores.some((item) => item?.includes("task2"))
    ? "ADSA"
    : bestScores.some((item) => item?.includes("task3"))
    ? "DF"
    : bestScores.some((item) => item?.includes("task4"))
    ? "DB"
    : "";

  const bestType = bestScores.map((scoreItem) => {
    return scoreItem?.includes("task1")
      ? "ADSV"
      : scoreItem?.includes("task2")
      ? "ADSA"
      : scoreItem?.includes("task3")
      ? "DF"
      : scoreItem?.includes("task4")
      ? "DB"
      : "";
  });

  const taskType2 = worstScores.some((item) => item?.includes("task1"))
    ? "ADSV"
    : worstScores.some((item) => item?.includes("task2"))
    ? "ADSA"
    : worstScores.some((item) => item?.includes("task3"))
    ? "DF"
    : worstScores.some((item) => item?.includes("task4"))
    ? "DB"
    : "";

  const worstType = worstScores.map((scoreItem) => {
    return scoreItem?.includes("task1")
      ? "ADSV"
      : scoreItem?.includes("task2")
      ? "ADSA"
      : scoreItem?.includes("task3")
      ? "DF"
      : scoreItem?.includes("task4")
      ? "DB"
      : "";
  });

  const GOOD_DATA: DataType = {
    ADSV: [
      {
        title: "를 잘 수행했어요.",
        [taskType1]: "알맞은 도형 찾기",
        [`${taskType1}_1`]: "우리 아이는 시각적 주의력이 좋아요!",
        [`${taskType1}_2`]:
          "는 특정 시각 자극에 대한 반응을 검사하는 과제입니다. 이를 통해 시각 자극에 대한 ",
        [`${taskType1}_3`]:
          " 주의력, 충동성, 정보처리속도, 지속적 주의력을 측정",
        [`${taskType1}_4`]:
          "알맞은 도형 찾기를 잘 수행한 어린이들은 시각 자극을 적절하게 처리하고 올바르게 반응할 수 있습니다. 대부분의 경우에는 책을 읽고 잘 이해할 수 있으며, 글자를 대충 읽고 실수하는 일이 없어서 문제를 잘 해결할 것으로 보입니다.",
      },
    ],

    ADSA: [
      {
        title: "를 잘 수행했어요.",
        [taskType1]: "알맞은 소리찾기",
        [`${taskType1}_1`]: "우리 아이는 청각적 주의력이 좋아요!",
        [`${taskType1}_2`]:
          "는 특정 청각 자극에 대한 반응을 검사하는 과제입니다. 이를 통해 청각 자극에 대한 ",
        [`${taskType1}_3`]:
          " 주의력, 충동성, 정보처리속도, 지속적 주의력을 측정",
        [`${taskType1}_4`]:
          "알맞은 소리찾기를 잘 수행한 어린이들은 청각 자극을 적절하게 처리하여 올바르게 반응할 수 있습니다. 수업 시간에 선생님 말씀에 경청하고 필요한 내용을 기억하는 데 문제가 없고, 공부를 할 때 불필요한 소리 자극을 무시하고 해야 할 것에 집중을 잘할 수 있습니다. 대부분의 경우에는 어떠한 상황에서도 집중력을 발휘할 수 있어서 효율적인 공부 습관을 지니게 됩니다.",
      },
    ],
    DF: [
      {
        title: "를 잘 수행했어요.",
        [taskType1]: "숫자 순서대로 기억하기",
        [`${taskType1}_1`]: "우리 아이는 숫자를 잘 기억해요!",
        [`${taskType1}_2`]:
          "는 시각적으로 제시되는 숫자를 그대로 기억하는 작업기억력의 단순 암기력을 검사하는 과제입니다. 이를 통해 ",
        [`${taskType1}_3`]: "주의력, 숫자 기억 용량을 측정",
        [`${taskType1}_4`]:
          "숫자 순서대로 기억하기를 잘 수행한 어린이들은 숫자를 보고 일정 시간 동안 기억할 수 있습니다. 정보를 수 초 동안 의식 속에 그대로 유지해 둘 수 있으며, 수업 시간에 보고 들은 정보를 그대로 기억할 수 있어서 정보를 가공하고 활용하는 데 유리합니다. ",
      },
    ],
    DB: [
      {
        title: "를 잘 수행했어요.",
        [taskType1]: "숫자 거꾸로 기억하기",
        [`${taskType1}_1`]: "우리 아이는 숫자를 잘 기억하고 활용해요!",
        [`${taskType1}_2`]:
          "는 시각적으로 제시되는 숫자를 기억하고 조작하는 것과 관련된 작업기억력의 응용 능력을 검사하는 과제입니다. 이를 통해 ",
        [`${taskType1}_3`]: "주의력, 숫자 기억 용량, 정신적 조작 능력을 측정",
        [`${taskType1}_4`]:
          "숫자 거꾸로 기억하기를 잘 수행한 어린이들은 숫자를 기억하여 활용할 수 있습니다. 많은 정보를 효율적으로 뇌에 입력하여 적절하게 변형할 수 있고, 학습 과제나 시험 상황에서 알고 있는 내용을 잘 활용할 수 있어서 학습에 유리합니다.",
      },
    ],
  };

  const BAD_DATA: DataType = {
    ADSV: [
      {
        title: " 점수가 아쉬워요.",
        [taskType2]: "알맞은 도형 찾기",
        [`${taskType2}_1`]: "우리 아이는 시각적 주의력이 부족해요!",
        [`${taskType2}_2`]:
          "는 특정 시각 자극에 대한 반응을 검사하는 과제입니다. 이를 통해",
        [`${taskType2}_3`]:
          " 시각 자극에 대한 주의력, 충동성, 정보처리속도, 지속적 주의력을 측정",
        [`${taskType2}_4`]:
          "알맞은 도형 찾기를 잘 수행하지 못한 어린이들은 시각 자극을 적절하게 처리하고 반응하는 능력이 부족합니다. 책을 읽거나 시험을 볼 때 문장을 제대로 읽지 않아서 문제를 틀리는 경우가 있고, 수학 부호나 숫자를 잘못 읽어서 실수할 가능성이 있습니다.",
      },
    ],
    ADSA: [
      {
        title: " 점수가 아쉬워요.",
        [taskType2]: "알맞은 소리찾기",
        [`${taskType2}_1`]: "우리 아이는 소리 찾기 과제 점수가 아쉬워요.",
        [`${taskType2}_2`]:
          "는 특정 청각 자극에 대한 반응을 검사하는 과제입니다. 이를 통해",
        [`${taskType2}_3`]:
          " 청각 자극에 대한 주의력, 충동성, 정보처리속도, 지속적 주의력을 측정",
        [`${taskType2}_4`]:
          "알맞은 소리찾기를 잘 수행하지 못한 어린이들은 청각 자극을 적절하게 처리하고 반응하는 능력이 부족합니다. 다른 사람과 대화할 때 중요한 내용을 놓쳐서 의사소통에 어려움을 겪을 수 있고, 공부를 할 때 불필요한 소리 자극에 의해 집중력이 쉽게 흐트러질 가능성이 있습니다.",
      },
    ],
    DF: [
      {
        title: " 점수가 아쉬워요.",
        [taskType2]: "숫자 순서대로 기억하기",
        [`${taskType2}_1`]: "우리 아이는 숫자를 기억하는 게 어려워요!",
        [`${taskType2}_2`]:
          "는 시각적으로 제시되는 숫자를 그대로 기억하는 작업기억력의 단순 암기력을 검사하는 과제입니다. 이를 통해",
        [`${taskType2}_3`]: " 주의력, 숫자 기억 용량을 측정",
        [`${taskType2}_4`]:
          "숫자 순서대로 기억하기를 잘 수행하지 못한 어린이들 숫자를 보고 일정 시간 동안 기억하는 능력이 부족합니다. 지시 사항을 금방 잊어버리고, 문장 따라 쓰기를 어려워하며, 수행 중인 과제를 완수하지 못하고 포기하는 경우가 있습니다.",
      },
    ],
    DB: [
      {
        title: " 점수가 아쉬워요.",
        [taskType2]: "숫자 거꾸로 기억하기",
        [`${taskType2}_1`]: "우리 아이는 숫자를 기억하고 활용하는 게 어려워요!",
        [`${taskType2}_2`]:
          "는 시각적으로 제시되는 숫자를 기억하고 조작하는 것과 관련된 작업기억력의 응용 능력을 검사하는 과제입니다. 이를 통해",
        [`${taskType2}_3`]: " 주의력, 숫자 기억 용량, 정신적 조작 능력을 측정",
        [`${taskType2}_4`]:
          "숫자 거꾸로 기억하기를 잘 수행하지 못한 어린이들은 숫자를 기억하여 활용하는 능력이 부족합니다. 정보를 뇌에 입력하고 적절하게 변형하는 게 어려울 수 있으며, 복잡한 단계의 수학 계산을 어려워할 수 있습니다.",
      },
    ],
  };

  return (
    <>
      {data && (
        <Wrapper>
          <Title>
            <div />
            <h1>과제 수행 점수 종합 분석</h1>
          </Title>
          <FirstGraphContainer>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <AbsoluteContainer>
              <h1>전체 과제 평균 점수</h1>

              <div>
                <div>
                  <h1>동 연령대 평균</h1>
                  <h2>{data?.totalTaskScoreAverage}점</h2>

                  <BoldLineChart
                    height={`${data?.totalTaskScoreAverage}px`}
                    bgColor="#EAEAEA"
                  >
                    <SVG_1 style={{ marginTop: "20px" }} />
                  </BoldLineChart>
                </div>
                <div>
                  <h3>측정 아동</h3>
                  <h4>{data?.totalTaskScore}점</h4>
                  <BoldLineChart
                    height={`${data?.totalTaskScore}px`}
                    bgColor="#76BDFF"
                  >
                    {data?.totalTaskScore === data?.totalTaskScoreAverage ? (
                      <SVG_3 style={{ marginTop: "20px" }} />
                    ) : data?.totalTaskScoreAverage > data?.totalTaskScore ? (
                      <SVG_4 style={{ marginTop: "20px" }} />
                    ) : data.totalTaskScore < 40 ? (
                      <></>
                    ) : (
                      <SVG_2 style={{ marginTop: "20px" }} />
                    )}
                  </BoldLineChart>
                </div>
              </div>
            </AbsoluteContainer>
          </FirstGraphContainer>
          <SecondGraphContainer>
            <div>각 과제 별 동 연령대 평균 점수 & 측정 아동 점수</div>
            <GraphContainer>
              <div>
                <div>
                  <h1>{data.adsvScoreAverage}</h1>
                  <LineChart
                    height={`${data?.adsvScoreAverage}px`}
                    bgColor="#EAEAEA"
                  />
                </div>
                <div>
                  <h2>{data.adsvScore}</h2>
                  <LineChart
                    height={`${data.adsvScore}px`}
                    bgColor={
                      data.bestScore?.includes("task1")
                        ? "#76BDFF"
                        : data.worstScore?.includes("task1")
                        ? "#F88DAD"
                        : "#A0A0A0"
                    }
                  />
                </div>
              </div>
              <Text
                width="110px"
                fontWeight={
                  data.bestScore?.includes("task1") ||
                  data.worstScore?.includes("task1")
                    ? "700"
                    : "400"
                }
              >
                알맞은 도형 찾기
              </Text>
            </GraphContainer>
            <GraphContainer>
              <div>
                <div>
                  <h1>{data.adsaScoreAverage}</h1>
                  <LineChart
                    height={`${data?.adsaScoreAverage}px`}
                    bgColor="#EAEAEA"
                  />
                </div>
                <div>
                  <h2>{data.adsaScore}</h2>
                  <LineChart
                    height={`${data.adsaScore}px`}
                    bgColor={
                      data.bestScore?.includes("task2")
                        ? "#76BDFF"
                        : data.worstScore?.includes("task2")
                        ? "#F88DAD"
                        : "#A0A0A0"
                    }
                  />
                </div>
              </div>
              <Text
                width="110px"
                fontWeight={
                  data.bestScore?.includes("task2") ||
                  data.worstScore?.includes("task2")
                    ? "700"
                    : "400"
                }
              >
                알맞은 소리 찾기
              </Text>
            </GraphContainer>
            <GraphContainer>
              <div>
                <div>
                  <h1>{data.dfScoreAverage}</h1>
                  <LineChart
                    height={`${data?.dfScoreAverage}px`}
                    bgColor="#EAEAEA"
                  />
                </div>
                <div>
                  <h2>{data.dfScore}</h2>
                  <LineChart
                    height={`${data.dfScore}px`}
                    bgColor={
                      data.bestScore?.includes("task3")
                        ? "#76BDFF"
                        : data.worstScore?.includes("task3")
                        ? "#F88DAD"
                        : "#A0A0A0"
                    }
                  />
                </div>
              </div>
              <Text
                width="100px"
                fontWeight={
                  data.bestScore?.includes("task3") ||
                  data.worstScore?.includes("task3")
                    ? "700"
                    : "400"
                }
              >
                숫자 순서대로 기억하기
              </Text>
            </GraphContainer>
            <GraphContainer>
              <div>
                <div>
                  <h1>{data.dbScoreAverage}</h1>
                  <LineChart
                    height={`${data?.dbScoreAverage}px`}
                    bgColor="#EAEAEA"
                  />
                </div>
                <div>
                  <h2>{data.dbScore}</h2>
                  <LineChart
                    height={`${data.dbScore}px`}
                    bgColor={
                      data.bestScore?.includes("task4")
                        ? "#76BDFF"
                        : data.worstScore?.includes("task4")
                        ? "#F88DAD"
                        : "#A0A0A0"
                    }
                  />
                </div>
              </div>
              <Text
                width="90px"
                fontWeight={
                  data.bestScore?.includes("task4") ||
                  data.worstScore?.includes("task4")
                    ? "700"
                    : "400"
                }
              >
                숫자 거꾸로 기억하기
              </Text>
            </GraphContainer>
          </SecondGraphContainer>
          {data.bestScore[0] !== "" && (
            <>
              {bestType.map((item) => (
                <GraphTextContainer
                  key={item}
                  border={data.bestScore?.length >= 1 ? "" : "none"}
                >
                  {GOOD_DATA[item]?.map((goodItem: TaskData) => (
                    <Footer key={goodItem.title} color="#4EA6F7">
                      <div>
                        <Img bgColor="#4EA6F7">
                          <SVG />
                        </Img>
                        <h1>{goodItem[taskType1]}</h1>
                        <h2>{goodItem.title}</h2>
                      </div>
                      <div>{goodItem[`${taskType1}_1`]}</div>
                      <div>
                        {goodItem[taskType1]}
                        {goodItem[`${taskType1}_2`]}
                        <span>{goodItem[`${taskType1}_3`]}</span>
                        합니다.
                      </div>
                      <div>{goodItem[`${taskType1}_4`]}</div>
                    </Footer>
                  ))}
                </GraphTextContainer>
              ))}
            </>
          )}

          {data.worstScore[0] !== "" && (
            <>
              {worstType.map((item) => (
                <GraphTextContainer
                  key={item}
                  border={data.worstScore?.length >= 1 ? "" : "none"}
                >
                  {BAD_DATA[item]?.map((badItem: TaskData) => (
                    <Footer key={badItem.title} color="#F88DAD">
                      <div>
                        <Img bgColor="#F88DAD">
                          <SVG />
                        </Img>
                        <h1>{badItem[taskType2]}</h1>&nbsp;
                        <h2>{badItem.title}</h2>
                      </div>
                      <div>{badItem[`${taskType2}_1`]}</div>
                      <div>
                        {badItem[taskType2]}
                        {badItem[`${taskType2}_2`]}
                        <span>{badItem[`${taskType2}_3`]}</span>
                        합니다.
                      </div>
                      <div>{badItem[`${taskType2}_4`]}</div>
                    </Footer>
                  ))}
                </GraphTextContainer>
              ))}
            </>
          )}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  width: 792px;
  margin: 0 auto;

  @media (max-width: 979px) {
    width: 318px;
  }
`;

const Title = styled.div`
  display: flex;
  margin-bottom: 24px;
  & > div {
    width: 4px;
    height: 23px;
    border-radius: 60px;
    background: #ffe9a3;
    margin-right: 8px;
  }

  & > h1 {
    color: #000;
    font-family: Cafe24 Ssurround OTF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }

  @media (max-width: 979px) {
    margin-bottom: 61px;
  }
`;

const FirstGraphContainer = styled.div`
  position: relative;
  height: 204px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid #d6d6d6;

  & > div {
    height: 24px;
    border-top: 1px solid #d9d9d9;
    opacity: 0.2;

    :nth-of-type(1) {
      margin-top: 104px;
    }
    :nth-last-of-type(1) {
      height: 195px;
      opacity: 1;
      border-top: none;
    }
  }

  @media (max-width: 979px) {
    height: 235px;

    & > div {
      :nth-of-type(1) {
        margin-top: 135px;
      }
    }
  }
`;

const AbsoluteContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 34px;
  left: 0;
  opacity: 1;

  & > h1 {
    position: absolute;
    top: -12px;
    left: 24px;
    color: #000;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & > div {
    display: flex;
    align-items: end;
    justify-content: center;
    width: 100%;
    height: 170px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 69px;

      :nth-of-type(1) {
        margin-right: 34px;
      }

      & > h1 {
        color: #a4a4a4;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
      }

      & > h2 {
        margin-bottom: 8px;
        color: #a4a4a4;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      & > h3 {
        color: #000;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 19.2px */
      }

      & > h4 {
        margin-bottom: 8px;
        color: #000;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  @media (max-width: 979px) {
    top: 65px;

    & > h1 {
      top: -43px;
    }
  }
`;

const SecondGraphContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 251px;
  border: 1px solid #d6d6d6;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 40px;

  & > div {
    :nth-of-type(1) {
      position: absolute;
      left: 24px;
      top: 24px;

      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  h1 {
    width: 20px;
    height: 20px;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
  }

  h2 {
    height: 20px;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media (max-width: 979px) {
    height: 451px;
    flex-wrap: wrap;
    padding-bottom: 24px;
    margin-bottom: 56px;

    & > div {
      :nth-of-type(1) {
        width: 192px;
      }
    }
  }
`;

const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  margin-bottom: 24px;
  margin-right: 56px;

  :nth-last-of-type(1) {
    margin-right: 0;
  }

  & > div {
    :nth-of-type(1) {
      display: flex;
      align-items: end;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 8px;

      :nth-of-type(1) {
        margin-right: 18.5px;
      }
    }
  }

  @media (max-width: 979px) {
    margin: 0;
    width: 147px;
  }
`;

const LineChart = styled.div<{ height: string; bgColor: string }>`
  width: 20px;
  height: ${(props) => props.height};
  background-color: ${(props) => props.bgColor};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const BoldLineChart = styled.div<{ height: string; bgColor: string }>`
  width: 50px;
  height: ${(props) => props.height};
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;

  background-color: ${(props) => props.bgColor};
`;

const Footer = styled.div`
  height: 249px;

  & > div {
    :nth-of-type(1) {
      display: flex;
      align-items: center;

      margin-bottom: 7px;

      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      & > h1 {
        color: ${(props) => props.color};
      }
    }

    :nth-of-type(2) {
      margin-bottom: 16px;

      color: #000;
      font-family: Cafe24 Ssurround OTF;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    :nth-of-type(3) {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 19.2px */

      & > span {
        font-weight: 700;
      }
    }

    :nth-of-type(4) {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 19.2px */
    }
  }
  @media (max-width: 979px) {
    height: 466px;
  }
`;

const Img = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};

  & > div {
    width: 11.75px;
    height: 11.75px;
  }
`;

const Text = styled.div<{ fontWeight: string; width: string }>`
  font-weight: ${(props) => props.fontWeight};
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  @media (max-width: 979px) {
    width: ${(props) => props.width};
  }
`;

const GraphTextContainer = styled.div<{ border: string }>`
  border-bottom: 1px solid #d6d6d6;
  border: ${(props) => props.border};
  margin-bottom: 40px;
  :nth-last-of-type(1) {
    border-bottom: none;
  }
`;
