import styled from "@emotion/styled";

export default function Index() {
  return (
    <>
      <ModalOverlay>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <VideoPlayer autoPlay muted controls>
            <source src="/images/emotivsample.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoPlayer>
        </ModalContent>
      </ModalOverlay>
    </>
  );
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  max-width: 90vw;
  max-height: 90vh;
  background: black;
`;
