import styled from "@emotion/styled";

import { ReportData } from "../../types/report";

import { ReactComponent as RECSVG } from "../../assets/rec.svg";

const DATA = {
  low: [
    {
      bold: "작업기억력이 낮은 어린이들은 정보 저장과 처리를 동시에 수행해야 하는 활동에 어려움을 겪으며, 암기나 암산, 연산에 어려움을 겪을 수 있습니다.",
      title:
        "문장 쓰기를 어려워할 수 있고, 복잡한 활동에서 자신의 순서를 놓치는 경우가 있으며, 과제를 수행하는 도중 방향성을 잃고 헤맬 수 있습니다. 또한, 직전에 보거나 들은 정보에 주의를 기울이고 즉시 회상하는 것을 잘 수행하지 못할 수 있으며, 이러한 문제들로 인하여 과제를 잘 완수하지 못하고 중간에 포기하는 경우가 발생하기도 합니다. 이들은 대체로 정보 처리 과정에서 낮은 수준의 전략을 사용하기 때문에, 활동을 효율적으로 수행하지 못할 가능성이 있습니다.",
      bold2: "학업 지연의 원인",
      tail: "이 될 수 있으므로 꾸준히 훈련하여 향상하는 것이 중요합니다.",
    },
  ],
  middle: [
    {
      bold: "작업기억력이 보통 수준인 어린이들은 정보를 효율적으로 뇌에 입력하고 기억할 수 있으며, 암기나 암산, 연산을 문제없이 수행할 수 있습니다.",
      title:
        "책의 내용이나 여러 가지 정보를 이해하여 순차적으로 저장할 수 있고, 새로 배운 내용을 기억하여 문제를 풀 수 있습니다. 다만, 때때로 정보 저장과 처리를 동시에 수행해야 하는 활동에 어려움을 겪을 수 있고, 복잡한 활동에서 자신의 순서를 놓칠 수 있으나, 대부분의 경우에는 과제를 잘 완수할 것으로 보입니다.",
      bold2: "수학 성취도나 언어 능력에도 영향",
      tail: "을 미칠 수 있으므로 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
  high: [
    {
      bold: "작업기억력이 높은 어린이들은 많은 정보를 효율적으로 뇌에 입력하고 기억할 수 있으며, 암기나 암산, 연산을 잘할 수 있습니다.",
      title:
        "과제를 수행하는 도중 방향성을 잃지 않고 잘 수행할 수 있으며, 여러 가지 정보를 정확하게 이해하여 순차적으로 저장할 수 있습니다. 또한, 여러 단계의 복잡한 활동을 잘 기억하고 수행할 수 있으며, 새로 배운 내용을 기억하여 보다 복잡한 사고 문제를 풀 수 있습니다.",
      bold2: "수학 성취도나 언어 능력",
      tail: "에도 영향을 미칠 수 있으므로 계속해서 잘 발달할 수 있도록 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
};

interface Props {
  data?: ReportData;
}

export default function Page8({ data }: Props) {
  const taskType =
    data?.memoryScoreEv === "높은"
      ? "high"
      : data?.memoryScoreEv === "보통"
      ? "middle"
      : "low";
  return (
    <Body>
      <Wrapper>
        <Title>작업기억력</Title>
        <TopContainer>
          <h1>
            작업기억력이란? <span>#읽기 #이해력 #논리력 #언어학습</span>
          </h1>
          <div>
            작업기억력은 정보를 머릿속에 일시적으로 보유하고 그 정보를 조작하여
            활용 할 수 있는 능력을 의미합니다. 일상적인 인지 활동뿐만 아니라
            새로운 지식이나 기술의 습득, 읽기, 계산하기, 언어이해, 추론 등의
            인지기능과도 밀접하게 관련되며, 학습 과정에서 요구되는 인지 활동의
            대부분은 작업기억에 의해 이루어 집니다. 이는 읽기 이해력을 예측할 수
            있는 주요 지표 중 하나이며, 논리력이나 언어 학습 등의 상위
            인지기능을 예측하는 요인으로 알려져 있습니다.
          </div>
          <RecSvg />
        </TopContainer>
        <MiddleContainer
          color={
            data?.memoryScoreEv === "높은"
              ? "#3689DC"
              : data?.memoryScoreEv === "보통"
              ? "#63AE18"
              : "#DE5C82"
          }
          bdColor={
            data?.memoryScoreEv === "높은"
              ? "#79BCFF"
              : data?.memoryScoreEv === "보통"
              ? "#87CA43"
              : "#F88DAD"
          }
        >
          <h1>작업기억력 관련 인지 측정 결과</h1>
          <h2>
            우리 아이의 작업기억력은{" "}
            <span>
              {data?.memoryScoreEv === "높은"
                ? "높은 수준"
                : data?.memoryScoreEv === "보통"
                ? "보통 수준"
                : "낮은 수준"}
              <h2>으로 추정됩니다.</h2>
            </span>
          </h2>
        </MiddleContainer>
        {DATA[taskType].map((item) => (
          <MiddleTextContainer key={item.title}>
            <h1>
              <span>{item.bold}</span> {item.title}
            </h1>
            <h2>
              작업기억력으로 인한 문제는 <span>{item.bold2}</span> {item.tail}
            </h2>
          </MiddleTextContainer>
        ))}
      </Wrapper>
      <Footer>
        <h1>
          <img src="/images/report/!.png" alt="" />
          재미있게 즐기는 훈련 방법을 통해 작업기억력을 키워보세요.
        </h1>
        <div>
          <div>
            <h2>이어 말하기 게임</h2>
            <p>
              '시장에 가면' 놀이와 같이 앞에 나온 단어들을 누적하여 기억하는
              게임을 통해 작업기억력을 훈련할 수 있습니다.
            </p>
          </div>
          <div>
            <h2>청킹 기법</h2>
            <p>
              기억해야 할 정보의 공통점을 찾아서 테마나 모양, 색상별로
              그룹화하는 연습을 통해 작업기억력을 훈련할 수 있습니다.
            </p>
          </div>
        </div>
      </Footer>
    </Body>
  );
}

const Body = styled.div`
  border-bottom: 8px solid #ededed;
`;

const Wrapper = styled.div`
  position: relative;
  width: 796px;
  margin: 0 auto;
  @media (max-width: 979px) {
    width: 318px;
  }
`;

const Title = styled.div`
  position: absolute;
  top: -23.5px;
  left: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 47px;
  background-color: #131313;
  border-radius: 55px;

  color: #fff;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  z-index: 2;

  @media (max-width: 979px) {
    left: 84px;
  }
`;

const TopContainer = styled.div`
  position: relative;
  width: 744px;
  height: 236px;
  padding: 24px;
  padding-top: 75px;
  margin-top: 143px;
  border: 1px solid #e8e8e8;
  background: #fff;

  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */

  & > h1 {
    margin-bottom: 8px;
    color: #000;
    font-family: Cafe24 Ssurround OTF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    & > span {
      color: #0084ff;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  & > div {
    :nth-of-type(1) {
      margin-bottom: 16px;
    }

    :nth-of-type(2) {
      padding-left: 24px;
    }

    & > span {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }
  }

  ol {
    list-style-type: decimal;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 514px;
    padding: 0;
    margin-top: 103px;

    & > h1 {
      margin-top: 38px;
      margin-left: 16px;
      & > span {
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }

    & > div {
      margin-left: 16px;
      :nth-of-type(1) {
        width: 286px;
      }

      :nth-of-type(2) {
        padding: 0 20px;
      }
    }
    li {
      margin-bottom: 8px;
    }
  }
`;

const RecSvg = styled(RECSVG)`
  position: absolute;
  bottom: -1px;
  right: -1px;
`;

const MiddleContainer = styled.div<{ bdColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 792px;
  height: 118px;
  border-radius: 10px;
  border: 3px solid ${(props) => props.bdColor};
  margin-top: 24px;
  margin-bottom: 24px;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  span {
    color: ${(props) => props.color};
  }

  & > h1 {
    margin-bottom: 8px;
  }

  & > h2 {
    & > span {
      h2 {
        display: inline-block;
      }
    }
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 126px;
    font-size: 20px;

    & > h1 {
      margin-bottom: 0;
    }
    & > h2 {
      text-align: center;

      & > span {
        display: block;
        h2 {
          display: inline-block;
          color: #000;
        }
      }
    }
  }
`;

const MiddleTextContainer = styled.div`
  height: 210px;
  margin-bottom: 24px;
  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  span {
    font-weight: 700;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 464px;
  }
`;

const Footer = styled.div`
  width: 796px;
  height: 243px;
  margin: 0 auto;
  margin-bottom: 120px;
  background: #f6f6f6;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  & > h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 24px;
    padding-left: 40px;
    padding-bottom: 20px;

    & > img {
      margin-right: 8px;
    }
  }
  & > div {
    display: flex;

    & > div {
      width: 325px;
      height: 123px;
      padding: 16px;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      :nth-of-type(1) {
        margin-left: 40px;
        margin-right: 16px;
      }

      & > h2 {
        margin-bottom: 8px;
        color: #000;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 22.4px */
      }

      & > p {
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
      }
    }
  }

  @media (max-width: 979px) {
    width: 100%;
    height: 528px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      padding-top: 40px;
      padding-left: 0;
      padding-right: 30px;
      width: 290px;
      & > img {
        margin-bottom: 24px;
      }
    }
    & > div {
      align-items: center;
      flex-direction: column;

      & > div {
        padding: 16px;
        width: 286px;
        height: 153px;
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        :nth-of-type(1) {
          margin: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
`;
