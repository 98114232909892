import styled from "@emotion/styled";

import { ReactComponent as GoogleSvg } from "../../assets/coupon/ic_google.svg";
import { ReactComponent as AppleSvg } from "../../assets/coupon/ic_apple.svg";
import { ReactComponent as DownArraowSvg } from "../../assets/coupon/icon.svg";
import { ReactComponent as UpArraowSvg } from "../../assets/coupon/blackIcon.svg";
import { useState } from "react";
export default function Login() {
  const [isStatus, setIsStatus] = useState(true);

  const handleLogin = (url: string) => {
    window.location.href = url;
  };

  return (
    <>
      <RudyImg />
      <Container>
        <Img
          url="/images/coupon/logo.png"
          width="140px"
          height="40px"
          mgRight="0"
        />
        <p>
          쿠폰을 등록하고
          <br />
          조이코그 서비스를 이용해보세요!
        </p>
        <CheckContainer>
          <h1>앗! 운영 체제 확인 하셨나요?👀</h1>
          <h2>
            반드시 조이코그를 사용하실 기기의
            <br />
            운영 체제로 간편 로그인을 해주세요!
          </h2>
          <h3>아이폰 - 애플로그인 / 안드로이드 - 구글로그인</h3>
        </CheckContainer>
        <LoginButton
          onClick={() =>
            handleLogin("https://webgl.dev.joycog.com/oauth2/authorize/apple")
          }
          color="#fff"
          bdColor="#fff"
          bgColor="#000"
        >
          <AppleSvg style={{ marginRight: "16px" }} />
          Apple 로그인/회원가입
        </LoginButton>

        <LoginButton
          onClick={() =>
            handleLogin("https://webgl.prod.joycog.com/oauth2/authorize/google")
          }
          color="#000"
          bdColor="#000"
          bgColor="#fff"
        >
          <GoogleSvg style={{ marginRight: "11px" }} />
          Google 로그인/회원가입
        </LoginButton>
      </Container>
      <CouponInfoContainer
        onClick={() => setIsStatus((prev) => !prev)}
        color={isStatus ? "#000" : "#FFFFFF"}
        bgColor={isStatus ? "#FFFFFF" : "#1d1d1d"}
        mgBottom={isStatus ? "260px" : "0"}
        bdRadius={isStatus ? "16px" : "0"}
      >
        <h1>쿠폰 등록 방법</h1>
        {isStatus ? <DownArraowSvg /> : <UpArraowSvg />}
      </CouponInfoContainer>
      {!isStatus && (
        <CouponInfoImg mgBottom={isStatus ? "0" : "260px"}>
          <button onClick={() => setIsStatus(true)}>
            <UpArraowSvg />
          </button>
        </CouponInfoImg>
      )}
    </>
  );
}

const Container = styled.div`
  width: 384px;
  min-height: 511px;
  margin-top: 220px;
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > p {
    margin-top: 16px;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 979px) {
    margin-top: 267px;
  }

  @media (max-width: 679px) {
    margin-top: 217px;
    width: 318px;
  }
`;

const CheckContainer = styled.div`
  background: url(/images/coupon/Vector.png) no-repeat;
  background-size: cover;
  width: 286px;
  height: 141px;
  margin-top: 32px;
  margin-bottom: 17px;

  & > h1 {
    margin-top: 16px;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & > h2 {
    margin: 10px 0;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & > h3 {
    color: var(--emotiv_black, #1d1d1d);
    text-align: center;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const LoginButton = styled.button<{ bdColor: string; bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 286px;
  height: 50px;
  margin-bottom: 16px;
  border-radius: 8px;
  background: ${(props) => props.bgColor};
  border: 1px solid ${(props) => props.bdColor};

  color: ${(props) => props.color};
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Img = styled.div<{
  url: string;
  width: string;
  height: string;
  mgRight: string;
}>`
  margin-top: 40px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: url(${(props) => props.url}) no-repeat;
  background-size: cover;
  margin-right: ${(props) => props.mgRight};
`;

const RudyImg = styled.div`
  background: url(/images/coupon/img_rudy.png) no-repeat;
  background-size: cover;
  width: 312px;
  height: 242px;
  top: 72px;
  position: absolute;

  @media (max-width: 979px) {
    top: 110px;
  }

  @media (max-width: 679px) {
    width: 250px;
    height: 194px;
    top: 88px;
  }
`;

const CouponInfoContainer = styled.div<{
  bgColor: string;
  mgBottom: string;
  bdRadius: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 329px;
  height: 73px;
  padding: 0 23px 0 32px;
  margin-bottom: 260px;
  margin-bottom: ${(props) => props.mgBottom};
  flex-shrink: 0;

  border-radius: ${(props) => props.bdRadius};
  background: ${(props) => props.bgColor};
  border-bottom: 1px solid #333;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  cursor: pointer;

  & > h1 {
    color: ${(props) => props.color};
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (max-width: 679px) {
    width: 263px;
  }
`;

const CouponInfoImg = styled.div<{ mgBottom: string }>`
  background: url(/images/coupon/couponInfo.png) no-repeat;
  background-size: cover;
  width: 384px;
  height: 3031px;
  margin-bottom: ${(props) => props.mgBottom};
  overflow-clip-margin: content-box;
  overflow: clip;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  & > button {
    position: -webkit-sticky;
    position: sticky;
    bottom: 12px;
    margin: 12px 10px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: #1d1d1d;
    border: black;
  }

  @media (max-width: 679px) {
    width: 318px;
    height: 2570.5px;
  }
`;
