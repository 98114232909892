import styled from "@emotion/styled";
import React from "react";

export default function Page11() {
  return (
    <Wrapper>
      <Title>
        <div />
        <h1>조이코그 인지능력 강화 프로그램</h1>
      </Title>
      <p>
        멀티태스킹(Multitasking) 훈련을 통해 간섭 조절(Interference control)
        능력을 훈련할 수 있습니다. 멀티태스킹 훈련은 주의력이나 자기조절 능력을
        관장하는 뇌 부위인 전두엽(Frontal lobe)의 활동을 강화하는 것으로 알려져
        있습니다.
      </p>
      <Logo />
      <p>
        <span>
          조이코그의 인지 강화 프로그램은 멀티태스킹 과제로 구성되어, 어린이의
          주의력을 향상하는 것을 목적으로 개발
        </span>
        되었습니다. 프로그램마다 억제력, 작업기억, 처리속도 등을 훈련할 수 있는
        과제를 포함하였으며, 이를 통해 여러 인지 영역을 종합적으로 훈련할 수
        있습니다.
      </p>
      <Img url="/images/report/page11_1.png" />
      <h1>
        01. 캐롯 러너<span>#주의력 #억제력 #처리속도</span>
      </h1>
      <p>
        탐사선을 주행하며 Go/No-go 과제를 수행하는 멀티태스킹 과제입니다.
        장애물을 피하고 쉴드존을 통과하도록 주행하는 동시에, 하늘에서 날아오는
        비표적 과제는 무시하고 표적 과제에만 빠르게 반응해야 합니다. 이를 통해
        어린이의 주의력, 억제력, 처리속도를 훈련할 수 있습니다.
      </p>

      <Img url="/images/report/page11_2.png" />
      <h1>
        02. 컬러 슈터<span>#주의력 #인지조절능력</span>
      </h1>
      <p>
        장애물을 피하며 Simon 과제를 수행해야 하는 멀티태스킹 과제입니다.
        레인에서 발사되는 총알을 피하는 동시에, 제시되는 표적의 색깔과 일치하는
        버튼을 빠르게 눌러야 합니다. 이를 통해 어린이의 주의력, 인지 조절 능력을
        훈련할 수 있습니다.
      </p>
      <Img url="/images/report/page11_3.png" />
      <h1>
        03. 점핑 래빗<span>#주의력 #작업기억력</span>
      </h1>
      <p>
        타이밍에 맞추어 장애물을 피하고 Spatial N-back 과제를 수행해야 하는
        멀티태스킹 과제입니다. 다가오는 장애물을 점프해서 피하는 동시에, 자극이
        나타나는 위치를 기억해서 알맞은 버튼을 눌러야 합니다. 이를 통해 어린이의
        주의력과 작업기억력을 훈련할 수 있습니다.
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  width: 792px;

  & > p {
    color: #000;
    text-align: justify;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */

    :nth-of-type(2) {
      margin-bottom: 32px;
    }

    :nth-of-type(3) {
      margin-bottom: 72px;
    }

    :nth-of-type(4) {
      margin-bottom: 120px;
    }

    :nth-of-type(5) {
      margin-bottom: 237px;
    }

    & > span {
      font-weight: 700;
    }

    :nth-of-type(2) {
      height: 139px;
    }
  }

  & > h1 {
    margin-top: 72px;
    margin-bottom: 16px;
    color: #000;
    color: #000;
    font-family: "Cafe24 Ssurround OTF";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    & > span {
      margin-left: 16px;
      color: #0084ff;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 28.8px */
    }
  }

  @media (max-width: 979px) {
    width: 318px;

    & > p {
      :nth-of-type(2) {
        margin-bottom: 48px;
      }

      :nth-of-type(3) {
        margin-bottom: 80px;
      }

      :nth-of-type(4) {
        margin-bottom: 80px;
      }

      :nth-of-type(5) {
        margin-bottom: 185px;
      }

      & > span {
        font-weight: 700;
      }

      :nth-of-type(2) {
        height: 172px;
      }
    }

    & > h1 {
      margin-top: 40px;

      & > span {
        display: block;
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
`;

const Title = styled.div`
  display: flex;
  margin-top: 120px;
  margin-bottom: 32px;

  & > div {
    width: 4px;
    height: 31px;
    border-radius: 60px;
    background: #76bdff;
    margin-right: 8px;
  }

  & > h1 {
    color: #000;
    font-family: "Cafe24 Ssurround OTF";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const Logo = styled.div`
  width: 340px;
  height: 170px;
  margin: 32px auto;
  background: url("/images/report/page12_1.png") no-repeat center;
  background-size: cover;

  @media (max-width: 979px) {
    width: 226px;
    height: 113px;
    margin: 32px auto 48px;
  }
`;

const Img = styled.div<{ url: string }>`
  margin: 0 auto;
  width: 582px;
  height: 282px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: cover;

  @media (max-width: 979px) {
    width: 294px;
    height: 142px;
  }
`;
