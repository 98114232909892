import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";

import DATA from "../../mock.json";
import { ReportData, ReportResultParams } from "../../types/report";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import base64 from "base-64";
import { postReportResult } from "../../api/report";

export default function Index() {
  const [data, setData] = useState<ReportData>();
  const [userInfo, setUserInfo] = useState<ReportResultParams>();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  useEffect(() => {
    if (token) {
      const parts = token.split(".");
      const dec = JSON.parse(base64.decode(parts[1]));
      setUserInfo(dec);
    }
  }, [location, token]);

  useEffect(() => {
    if (userInfo) {
      try {
        postReportResult(
          {
            userId: userInfo.userId,
            screenCount: userInfo.screenCount,
          },
          token
        )
          .then((data) => {
            setData(data.data.data?.report);
          })
          .catch((error) => {
            console.error("API 요청 실패:", error);
          });
      } catch (error) {
        console.error("base64 문자열 디코딩 오류:", error);
      }
    }
  }, [token, userInfo]);

  return (
    <>
      {data && (
        <Container>
          <Page1 data={data} />
          <Page2 data={data} />
          <Page3 data={data} />
          <Page4 data={data} />
          <Page5 />
          <Page6 data={data} />
          <Page7 data={data} />
          <Page8 data={data} />
          <Page9 data={data} />
          <Page10 />
          <Page11 />
          <Footer />
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  margin: 0 auto;
`;
