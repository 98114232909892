import styled from "@emotion/styled";

import { ReportData } from "../../types/report";

import { ReactComponent as RECSVG } from "../../assets/rec.svg";

const DATA = {
  low: [
    {
      bold: "억제력이 낮은 어린이들은 심사숙고 없이 순간적으로 성급하게 행동하고 상황에 적절하지 않은 행동을 보일 수 있습니다.",
      title:
        "수업 시간에 떠들고 장난치거나 돌아다니는 경우가 많고, 순서나 차례를 잘 기다리지 못할 수 있습니다. 친구의 허락을 구하지 않고 물건을 함부로 만져서 다툼이 벌어질 수 있고, 다른 사람이 말하는 도중에 끼어들어서 의사소통에 방해를 줄 수 있으며, 사회적으로 부적절한 정도로 참견하여 다른 사람에게 불쾌감을 줄 수 있습니다. 또한, 때때로 자신의 욕구가 좌절되는 상황에서 과도한 감정적, 행동적 반응을 보이기도 합니다.",
      bold2: "",
      text: "",
      tail: "이러한 문제가 지속된다면 사회적 기술을 습득하는 데 어려움을 겪을 수 있고 문제 행동이 외현화되어 발현될 수 있으므로 꾸준히 훈련하여 억제력을 향상하는 것이 중요합니다.",
    },
  ],
  middle: [
    {
      bold: "억제력이 보통 수준인 어린이들은 대체로 불필요한 자극을 억제하고 조절할 수 있고, 상황이나 문제를 파악하고 집중할 수 있습니다.",
      title:
        "오랫동안 인내하고 지속해서 수행해야 하는 과제를 문제없이 수행할 수 있고, 규칙과 순서가 정해져 있는 게임을 즐길 수 있습니다. 때때로 성급한 행동을 보이거나 과도한 반응을 보이는 등, 상황에 적절하지 않은 행동을 보일 수 있으나, 대체로 사회적 상황에서 적절하게 행동할 수 있습니다.",
      bold2: "여러 영역과 학습에 많은 영향",
      text: "억제력은",
      tail: "을 미치므로 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
  high: [
    {
      bold: "억제력이 높은 어린이들은 불필요한 자극을 억제하고 조절할 수 있습니다. 주변 자극에 충동적으로 반응하지 않아서 상황이나 문제를 정확하게 파악하고 집중할 수 있습니다.",
      title:
        "오랫동안 인내하고 지속해서 수행해야 하는 과제에서 성취를 이뤄낼 가능성이 높고, 어린이의 욕구에 반하지만 의무적으로 해야 하는 행동도 잘 수행할 수 있습니다. 사회적 상황에서 적절하게 행동할 수 있으며, 규칙과 순서가 정해져 있는 게임을 즐길 수 있습니다. 심사숙고하여 신중한 결정을 내릴 수 있고, 즉각적인 보상이 주어지지 않아도 인내할 수 있습니다.",
      bold2: "여러 영역과 학습에 많은 영향",
      text: "억제력은",
      tail: "을 미치므로 계속해서 잘 발달할 수 있도록 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
};

interface Props {
  data?: ReportData;
}

export default function Page7({ data }: Props) {
  const taskType =
    data?.impulScoreEv === "높은"
      ? "high"
      : data?.impulScoreEv === "보통"
      ? "middle"
      : "low";

  return (
    <Body>
      <Wrapper>
        <Title>억제력</Title>
        <TopContainer>
          <h1>
            억제력이란? <span>#사회및정서적발달 #학업성취</span>
          </h1>
          <div>
            억제력은 충동에 이끌려 행동하지 않고 자제할 수 있는 능력으로,
            자동적인 반응이나 불필요한 자극을 억누르고 더 적절한 방식으로
            사고하고 행동하는 것을 말합니다. 이는 목표에 대한 방해 요소를
            무시하는 것, 자신의 차례를 기다리는 것, 불필요한 말을 억누르는 것
            등과 같이 사고와 행동을 적절하게 조절하기 위해 요구되는 능력이며,
            주의 조절이나 인지적 통제, 행동적 억제, 반응 억제력, 자기 조절 능력
            등의 다양한 개념을 포괄합니다. 이는 사회 및 정서적 발달, 학업 성취
            등을 예측할 수 있는 중요한 변인입니다.
          </div>
          <RecSvg />
        </TopContainer>
        <MiddleContainer
          color={
            data?.impulScoreEv === "높은"
              ? "#3689DC"
              : data?.impulScoreEv === "보통"
              ? "#63AE18"
              : "#DE5C82"
          }
          bdColor={
            data?.impulScoreEv === "높은"
              ? "#79BCFF"
              : data?.impulScoreEv === "보통"
              ? "#87CA43"
              : "#F88DAD"
          }
        >
          <h1>억제력 관련 인지 측정 결과</h1>
          <h2>
            우리 아이의 억제력은{" "}
            <span>
              {data?.impulScoreEv === "높은"
                ? "높은 수준"
                : data?.impulScoreEv === "보통"
                ? "보통 수준"
                : "낮은 수준"}
              <h2>으로 추정됩니다.</h2>
            </span>
          </h2>
        </MiddleContainer>
        {DATA[taskType].map((item) => (
          <MiddleTextContainer key={item.title}>
            <h1>
              <span>{item.bold} </span>
              {item.title}
            </h1>
            <h2>
              {item.text} <span>{item.bold2}</span>
              {item.tail}
            </h2>
          </MiddleTextContainer>
        ))}
      </Wrapper>
      <Footer>
        <h1>
          <img src="/images/report/!.png" alt="" />
          재미있게 즐기는 훈련 방법을 통해 억제력을 키워보세요.
        </h1>
        <div>
          <div>
            <h2>역할 놀이</h2>
            <p>
              역할 놀이 활동을 통해 말이나 행동을 하기 전에 한 번 더 생각하는
              연습을 함으로써 억제력을 훈련할 수 있습니다.
            </p>
          </div>
          <div>
            <h2>보드 게임</h2>
            <p>
              보드게임을 통해 순서와 규칙을 이해할 수 있고, 이기거나 졌을 때
              감정을 통제하는 방식을 배우며 억제력을 훈련할 수 있습니다.
            </p>
          </div>
        </div>
      </Footer>
    </Body>
  );
}

const Body = styled.div`
  border-bottom: 8px solid #ededed;
`;

const Wrapper = styled.div`
  position: relative;
  width: 796px;
  margin: 0 auto;
  @media (max-width: 979px) {
    width: 318px;
  }
`;

const Title = styled.div`
  position: absolute;
  top: -23.5px;
  left: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  height: 47px;
  background-color: #131313;
  border-radius: 55px;

  color: #fff;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  z-index: 2;

  @media (max-width: 979px) {
    left: 107px;
  }
`;

const TopContainer = styled.div`
  position: relative;
  width: 744px;
  height: 236px;
  padding: 24px;
  padding-top: 75px;
  margin-top: 143px;
  border: 1px solid #e8e8e8;
  background: #fff;

  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */

  & > h1 {
    margin-bottom: 8px;
    color: #000;
    font-family: Cafe24 Ssurround OTF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    & > span {
      color: #0084ff;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  & > div {
    :nth-of-type(1) {
      margin-bottom: 16px;
    }

    :nth-of-type(2) {
      padding-left: 24px;
    }

    & > span {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }
  }

  ol {
    list-style-type: decimal;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 514px;
    padding: 0;
    margin-top: 103px;

    & > h1 {
      margin-top: 38px;
      margin-left: 16px;
      & > span {
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }

    & > div {
      margin-left: 16px;
      :nth-of-type(1) {
        width: 286px;
      }

      :nth-of-type(2) {
        padding: 0 20px;
      }
    }
    li {
      margin-bottom: 8px;
    }
  }
`;

const RecSvg = styled(RECSVG)`
  position: absolute;
  bottom: -1px;
  right: -1px;
`;

const MiddleContainer = styled.div<{ bdColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 792px;
  height: 118px;
  border-radius: 10px;
  border: 3px solid ${(props) => props.bdColor};
  margin-top: 24px;
  margin-bottom: 24px;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  span {
    color: ${(props) => props.color};
  }

  & > h1 {
    margin-bottom: 8px;
  }

  & > h2 {
    & > span {
      h2 {
        display: inline-block;
      }
    }
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 126px;
    font-size: 20px;

    & > h1 {
      margin-bottom: 0;
    }
    & > h2 {
      text-align: center;

      & > span {
        display: block;
        h2 {
          display: inline-block;
          color: #000;
        }
      }
    }
  }
`;

const MiddleTextContainer = styled.div`
  height: 210px;
  margin-bottom: 24px;
  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  span {
    font-weight: 700;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 464px;
  }
`;

const Footer = styled.div`
  width: 796px;
  height: 243px;
  margin: 0 auto;
  margin-bottom: 120px;
  background: #f6f6f6;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  & > h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 24px;
    padding-left: 40px;
    padding-bottom: 20px;

    & > img {
      margin-right: 8px;
    }
  }
  & > div {
    display: flex;

    & > div {
      width: 325px;
      height: 123px;
      padding: 16px;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      :nth-of-type(1) {
        margin-left: 40px;
        margin-right: 16px;
      }

      & > h2 {
        margin-bottom: 8px;
        color: #000;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 22.4px */
      }

      & > p {
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
      }
    }
  }

  @media (max-width: 979px) {
    width: 100%;
    height: 528px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      padding-top: 40px;
      padding-left: 0;
      padding-right: 30px;
      width: 290px;
      & > img {
        margin-bottom: 24px;
      }
    }
    & > div {
      align-items: center;
      flex-direction: column;

      & > div {
        padding: 16px;
        width: 286px;
        height: 153px;
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        :nth-of-type(1) {
          margin: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
`;
