import styled from "@emotion/styled";
import React, { useRef } from "react";

interface Props {
  onClose?: () => void;
  subTitle?: string;
  title?: string;
}

const Modal = ({ onClose, title, subTitle }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose?.();
    }
  };

  return (
    <Overlay onClick={handleClickOutside}>
      <SubContent ref={modalRef}>
        <StatusContainer>
          <div>
            <h1>{title}</h1>
            <h2>{subTitle}</h2>
          </div>
          <button onClick={onClose}>확인</button>
        </StatusContainer>
      </SubContent>
    </Overlay>
  );
};

const Overlay = styled.div`
  z-index: 99;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubContent = styled.div`
  display: flex;
  justify-content: center;
  width: 652px;
  height: 374px;
  border-radius: 16px;
  background: #fff;
  z-index: 999999;

  @media (max-width: 679px) {
    width: 360px;
    height: 261px;
  }
`;

const StatusContainer = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 263px;
    & > h1 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 48px */
    }

    & > h2 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 39px */
    }
  }
  & > button {
    width: 588px;
    padding: 25px 127px;
    border-radius: 8px;
    background: #1c1c1c;

    color: #fff;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }

  @media (max-width: 679px) {
    & > div {
      height: 176px;

      & > h1 {
        font-size: 20px;
      }

      & > h2 {
        font-size: 18px;
      }
    }

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 286px;
      height: 69px;
      padding: 0;
    }
  }
`;

export default Modal;
